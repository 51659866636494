
document.addEventListener("DOMContentLoaded", async () => {
	const { mainPage } = await import('./modules/main/page.js'),
		{ routes } = await import('./routes.js'),
		{ Router } = await import('./routing/index.js'),
		$body = document.querySelector('body');

	$body.innerHTML = '';

	mainPage({
		user: window.loggedInUser || null,
	})($body);

	const router = new Router(routes);

	window.phroggy = {
		router,
		title: (title=null) => {
			document.title = `phroggy.me ${title === null ? '' : `- ${title}`}`;
		},
	};

	await router.route(window.location);
});
